<template lang="html">
  <div>
    <section>
      <div class="module__switcher" v-if="active_product.hasOwnProperty('images')">
        <div class="switcher__header">
          <ul class="switcher__header__options">
            <li v-for="product in products" :key="product.id" v-on:click="switchActiveProduct(product)">
              <div class="switcher__header__option" :class="[product.slug,  {'active': product.slug == active }]">
                <a class="button button--switcher" v-html="product.name"></a>
                <div class="description" v-html="product.usecase_short_description"></div>
              </div>
            </li>
          </ul>
        </div>

        <div class="switcher__mobile" ref="mobileSwitcher">
          <div class="switcher__mobile__active-product" ref="mobileSwitcherTrigger" @click="expand">
            <template v-if="!mobileSwitcherOpen">
              <div>
                <h3 class="name" v-html="active_product.name"></h3>
                <div class="description" v-html="active_product.usecase_short_description"></div>
              </div>
            </template>
            <template v-else>
              <div>
                <h3 class="choose">Choose your product</h3>
              </div>
            </template>
            <i class="icon icon--arrow--down--white"></i>
          </div>
          <ul class="switcher__mobile__options" ref="mobileSwitcherOptions">
            <li v-for="product in products" :key="product.id" v-on:click="mobileSwitchActiveProduct(product)" :class="{'active': product.slug == active }">
              <div class="switcher__mobile__option" :class="[product.slug,  {'active': product.slug == active }]">
                <div>
                  <h3 class="name" v-html="product.name"></h3>
                  <div class="description" v-html="product.usecase_short_description"></div>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <div class="switcher__body">
          <div class="switcher__body__container">
          
            <div class="switcher__gallery">
              <gallery :images="active_product.images" :key="key" />
            </div>

            <div class="switcher__info">
              <div class="switcher__info__wrapper">
                <form class="form__buy" action="index.html" method="post">
                  <div class="switcher__info__header">
                    <p class="switcher__info__product__name" v-html="active_product.name"></p>
                    <h2 class="switcher__info__product__use-case">{{ active_product.usecase_title }}</h2>

                    <div class="switcher__mobile-gallery">
                      <mobile-gallery :images="active_product.images" />
                    </div>

                    <p class="switcher__info__product__description" v-html="active_product.usecase_description"></p>
                  </div>

                  <!-- <div class="switcher__info__kit-options">
                    <div class="switcher__info__kit-options__option">
                      <input type="radio" name="kit-option" id="kit-option_2-person" v-model="install_kit" value="2-person" checked>
                      <label for="kit-option_2-person">2-person easy install kit</label>
                    </div>

                    <div class="switcher__info__kit-options__option">
                      <input type="radio" name="kit-option" id="kit-option_1-person" v-model="install_kit" value="1-person">
                      <label for="kit-option_1-person">1-person easy install kit</label>
                    </div>
                  </div> -->

                  <div class="switcher__info__includes" v-if="active_product.body" v-html="active_product.body">
                  </div>

                  <div class="switcher__info__price-per">
                    <p>{{ active_product.price | currency(currencySymbol) }} + VAT / m&sup2;</p>
                  </div>

                  <div class="switcher__calculator">
                    <div class="switcher__calculator__upper">
                      <div class="calculator">
                        <div class="calculator__container">
                          <div class="calculator__left">
                            <div class="calculator__quantity">
                              <span id="calculator_decrement" class="calculator__quantity__minus" v-on:click="decrement()">-</span>

                              <div class="calculator__quantity__input">
                                <input id="calculator_quantity" type="number" min="0" pattern="\d+" onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57" step="1" name="quantity"  v-model.number="quantity" ><span>m&sup2;</span>
                              </div>

                              <span id="calculator_increment" class="calculator__quantity__plus" v-on:click="increment()">+</span>
                            </div>

                            <div class="calculator__total">
                              <p>= <span>{{ price | currency(currencySymbol) }}</span> &plus; VAT</p>
                            </div>
                          </div>

                          <div class="calculator__buy">
                            <button class="button button--purple button--wide" type="button" id="add_to_cart" @click="buy" name="buy">Buy</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="switcher__calculator__delivery">
                      <i class="icon icon--calculator--delivery"></i><p><span>Free weekday delivery</span> on all orders of 20m&sup2; or more</p>
                    </div>

                    <div class="switcher__calculator__lower">
                      <div class="switcher__calculator__co2">
                        <div class="switcher__calculator__co2__left">
                          <p><span>{{co2}}</span>kg</p>
                        </div>
                        <div class="switcher__calculator__co2__right">
                          <p>of CO<span>2</span> sequestered<br> by your roof per year</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <extras :extras="extras" :currencySymbol="currencySymbol"></extras>
    <product-details :product="active_product"></product-details>
  </div>
</template>

<script>
import Api from "../utils/api";
import EventBus from '../utils/event-bus';
import Gallery from './gallery';
import MobileGallery from './mobile-gallery';
import Extras from '../components/extras.vue';
import ProductDetails from '../components/product_details.vue';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

export default {
  components: {
    Extras,
    Gallery,
    MobileGallery,
    ProductDetails,
  },

  props: {
    products: {},
    extras: {},
    currency: {
      type: String,
      default: 'GBP'
    },
    activeproduct: {
      type: String,
      default: "evermat-core"
    }
  },

  data: function () {
    return {
      quantity: 0,
      active: this.activeproduct,
      install_kit: "2-person",
      mobileSwitcherOpen: false,
      key: 0
    }
  },

  mounted: function() {
    this.active_image = this.active_product.images[0].url;

    history.listen((location, action) => {
      if(action === "POP") {
        this.active = location.state.slug
        this.key += 1
        this.mobileSwitcherOpen = false;
        this.$refs.mobileSwitcher.classList.remove('open');
        this.$refs.mobileSwitcherTrigger.classList.remove('active');
      }
    })

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'view_item',
      'ecommerce': {
        'value': parseFloat(this.active_product.price),
        'currency': this.currency,
        'items': [{
          'item_id': this.active_product.slug,
          'item_name': this.active_product.name.replace("&trade;",""),
          'category': this.active_product.category,
          'price': parseFloat(this.active_product.price),
          'quantity': 1
        }]
      }
    });
  },

  computed: {

    currencySymbol: function() {
      switch (this.currency) {
        case 'GBP':
          return '£';
        case 'EUR':
          return '€';
        case 'USD':
          return '$';
        default:
          return '£';
      }
    },

    active_product: function() {
      return this.products.filter(p => p.slug == this.active)[0]
    },
    price: function() {
      return parseFloat(this.active_product.price) * this.quantity;
    },
    co2: function() {
      return Math.round(parseFloat(this.active_product.co2) * this.quantity * 10)/10;
    },
    stormwater: function() {
      return parseInt(this.active_product.stormwater) * this.quantity;
    },
  },

  methods: {
    switchActiveProduct: function(product) {
      this.key += 1;
      this.active = product.slug;
      history.push(`/products/${this.active}`, {slug: this.active})
    },
    mobileSwitchActiveProduct: function(product) {
      this.key += 1;
      this.active = product.slug;
      this.mobileSwitcherOpen = false;
      this.$refs.mobileSwitcher.classList.remove('open');
      this.$refs.mobileSwitcherTrigger.classList.remove('active');
      history.push(`/products/${this.active}`, {slug: this.active})
      window.scrollTo(0, 0);
    },
    increment: function() {
      this.quantity += 1;
    },
    decrement: function() {
      this.quantity -= 1;
      if (this.quantity < 0) {
        this.quantity = 0;
      }
    },
    buy: function() {
      if (this.quantity > 0) {
        Api.post('/basket/add', {  
          product_id: this.active_product.id,
          install_kit: this.install_kit,
          quantity: this.quantity,
        })
        .then(() => {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'event': 'add_to_cart',
            'ecommerce': {
              'value': parseFloat(this.price),
              'currency': this.currency,
              'items': [{
                'item_id': this.active_product.slug,
                'item_name': this.active_product.name.replace("&trade;",""),
                'category': this.active_product.category,
                'price': parseFloat(this.active_product.price),
                'quantity': this.quantity
              }]
            }
          });
          
          EventBus.$emit('update_basket');
          window.location = "/checkout"
        })
        .catch((e) => {
          console.log(e)
        })
      }
    },
    
    expand: function() {
      let trigger = this.$refs.mobileSwitcherTrigger;
      let switcher = this.$refs.mobileSwitcher;

      if(!this.mobileSwitcherOpen) {
        trigger.classList.add('active');
        switcher.classList.add('open');
        this.mobileSwitcherOpen = true;
      } else {
        trigger.classList.remove('active');
        switcher.classList.remove('open');
        this.mobileSwitcherOpen = false;
      }
    }
  }    
}
</script>

<style lang="scss">


</style>
